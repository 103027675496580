<template>
  <div>
    <transition name="fade">
      <div>
        <v-row class="py-5">
          <v-col cols="12">
            <v-card class="border10">
              <v-row class="pa-4 pb-8">
                <h4 style="vertical-align: center" class="ml-4">
                  <span label class="ma-3" :style="{'color': color}">
                    {{ datatableName }}
                  </span>
                </h4>
                <v-spacer></v-spacer>
              </v-row>
              <v-divider></v-divider>

             
              <v-expand-transition>
                <div v-show="expandDatatable">
                  <v-row v-if="reloadingReports">
                    <v-col cols="12">
                      <v-card :loading="true" :elevation="0">
                        <v-skeleton-loader
                          type="table-heading, divider, list-item-two-line, list-item-avatar, list-item-avatar, list-item-avatar, divider, table-tfoot"
                        ></v-skeleton-loader>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-card-text v-show="!reloadingReports">
                    <div class="reports-table" ref="table"></div>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </transition>
    <v-dialog v-model="confirmDialog" max-width="500">
      <v-card>
        <v-card-title>
          {{ confirmDialogTitle }}
        </v-card-title>
        <v-card-text>{{ confirmDialogBody }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="confirmDialog = false">{{ confirmYesNo ? $t("reportInfo.no") : $t("confirm.cancel") }}</v-btn>
          <v-btn color="green darken-1" text @click="confirmDialogYes()">{{ confirmYesNo ? $t("reportInfo.yes") : $t("confirm.ok") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { TabulatorFull as Tabulator } from 'tabulator-tables';
// import "tabulator-tables/dist/css/tabulator.min.css";
import "tabulator-tables/dist/css/tabulator_bootstrap3.min.css";

import { mapActions, mapMutations, mapState } from "vuex";
import { getLanguage, getLanguageWithoutRegion, getTabulatorLangsObject, displayPaginationInfo } from "@/utils/utils";
import constants from "@/constants";
import authHeader from "@/services/auth-header";
import DocumentRequestService from "@/services/documentRequest.service";
import { DOCUMENT_REQUEST_STATUS, DOCUMENT_REQUEST_STATUS_TEXT, FUNCTIONS } from '@/enums'
import i18n from "@/i18n";
const ConfirmAction = Object.freeze({ ArchiveDocumentRequest: 0});
var scope = null;
var tables = {};

function confirmStatusChange(documentRequestId, oldStatusEnum, newStatusEnum) { // eslint-disable-line
  scope.confirmStatusChange(documentRequestId, oldStatusEnum, newStatusEnum);
}
window.confirmStatusChange = confirmStatusChange;

function openMap(projectId) { // eslint-disable-line
  scope.openMap(projectId);
}
window.openMap = openMap;

function openReport(reportId) { // eslint-disable-line
  scope.$router.push('/report/' + reportId)
}
window.openReport = openReport;

export default {
  name: "ReportsDatatable",
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    documentRequestStatus: {
      type: Number,
      default: 0,
    },
    datatableName: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "green",
    },
    expandedByDefault: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default() {
        return []
      }
    },
    generalSearch: {
      type: String,
      default: "",
    }
  },
  watch: {
    columns() {
      tables[this.documentRequestStatus].setColumns(this.getColumns);
      tables[this.documentRequestStatus].refreshFilter();
    },
    generalSearch() {
      tables[this.documentRequestStatus].refreshFilter();
    }
  },
  data() {
    return {
      expandDatatable: false,
      reports: [],
      reloadingReports: false,
      totalCount: null,
      confirmDialog: false,
      confirmDialogTitle: null,
      confirmDialogBody: null,
      confirmAction: null,
      confirmYesNo: true,
      confirmPendingData: null
    };
  },
  methods: {
    ...mapMutations([
      'showErrorMessage',
      'openToast'
    ]),
    ...mapActions({
    }),
    editReport(item) {
      console.log("Edit report", item);
      this.$emit("editReport", item);
    },
    openMap(projectId) {
      for (let tableKey in tables) {
        let table = tables[tableKey];
        let report = table.getData().find(r => r.id == projectId);
        console.log('report', report)
        if (report) {
          this.$emit('onShowMap', report)
          break;
        }
      }
    },
    confirmStatusChange(documentRequestId, oldStatusEnum, newStatusEnum) {
      this.documentRequestId = documentRequestId;
      this.oldStatusEnum = oldStatusEnum;
      this.newStatusEnum = newStatusEnum;
      this.confirmDialog = true;
      this.confirmDialogTitle = this.$t('confirm.head');
      this.confirmDialogBody = this.$t('confirm.engineering_request_status_to_x', {status:this.$t(DOCUMENT_REQUEST_STATUS_TEXT[newStatusEnum])});
      this.confirmAction = ConfirmAction.ArchiveDocumentRequest;
      this.confirmYesNo = true;
    },
    confirmDialogYes() {
      switch (this.confirmAction) {
        case ConfirmAction.ArchiveDocumentRequest:
          this.updateStatusDocumentRequest(this.documentRequestId, this.oldStatusEnum, this.newStatusEnum);
          break;
        default:
          break;
      }
      this.confirmDialog = false;
    },
    
    async updateStatusDocumentRequest(documentRequestId, oldStatusEnum, newStatusEnum) {
      try {
        this.isSaving = true;
        let response = await DocumentRequestService.updateStatus(documentRequestId, newStatusEnum);
        this.isSaving = false;
        if (response.status == 200) {
          tables[oldStatusEnum].refreshFilter();
          tables[newStatusEnum].refreshFilter();
        }
        else {
          this.openToast({ message: response, duration: 15000, color: 'red'});
        }
      }
      catch (error) {
        this.isSaving = false;
        console.log(error)
        this.openToast({ message: error, duration: 15000, color: 'red'});
      }
    },

  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      userFunctions: (state) => state.userFunctions
    }),
    getColumns() {

      // Si le rapport n'est pas en draft, enlever l'editeur de cellule
      let columns = JSON.parse(JSON.stringify(this.columns));
      if (this.documentRequestStatus != DOCUMENT_REQUEST_STATUS.DRAFT) {
        for (let column of columns) {
          delete column.editor;
          delete column.editorParams;
        }
      }

      return columns;
    }
  },
  mounted() {
    scope = this;
    var localScope = this;

    let columnsLangData = { 
      "formattedDealerName": i18n.t("filters.dealer"),
      "formattedUpdatedAt": i18n.t("reportInfo.updated_at"),
      "formattedProjectNumber": i18n.t("projects.project_number"),
      "formattedClient": i18n.t("reportInfo.client"),
      "formattedSiteAddress": i18n.t("reportInfo.site_address"),
      "formattedTypeEnum": i18n.t("documentRequests.typeEnum")
    };

    tables[this.documentRequestStatus] = new Tabulator(this.$refs.table, {
      documentRequestStatus:this.documentRequestStatus,
      layout: "fitColumns",
      columns: this.getColumns,
      persistence: { sort: true, filter: true, columns: false },
      persistenceID: "persistenceDocumentRequests" + this.documentRequestStatus,
      pagination: true,
      paginationSize: 10,
      paginationSizeSelector: [10, 25, 50, 100],
      paginationMode: "remote",
      paginationCounter: (pageSize, currentRow, currentPage, totalRows, totalPages) => displayPaginationInfo(pageSize, currentRow, currentPage, totalRows, totalPages, localScope),
      filterMode: "remote",
      sortMode: "remote",
      ajaxURL: constants.API_URL + `user/webDocumentRequests/${this.documentRequestStatus}?language=${getLanguage()}`,
      ajaxConfig: {
        method: "POST",
        headers: authHeader(),
      },
      ajaxContentType:{
        headers:{
            'Content-Type': 'application/json; charset=utf-8',
        },
        body: function(url, config, params) {
          let data = {
            language: getLanguage(),
            generalSearch: localScope.generalSearch,
            columns: localScope.columns.filter(c => c.field != 'actions').map(c => c.field)
          };
          Object.assign(params, data);
          return JSON.stringify(params);
        },
      },
      ajaxResponse: function (url, params, response) {
        console.log('response', response, url, params)

        for (let dr of response.data) {
          dr.formattedSiteAddress = `<span class="project-address">${dr.formattedSiteAddress}</span>`;
          if (dr.siteAddressLongitude != null && dr.siteAddressLatitude != null) {
            dr.formattedSiteAddress += `<span class="mdi mdi-map-marker datatable-marker" onclick="openMap(${dr.id})"></span>`;
          }
          dr.actions = `<span class="mdi mdi-pencil datatable-pencil" onclick="openReport(${dr.siteReportId})"></span>`;
          if (this.options.documentRequestStatus == DOCUMENT_REQUEST_STATUS.COMPLETED && scope.userFunctions.find(uf => uf.id == FUNCTIONS.ACCESS_TO_ALL_DEALERS)){
            dr.actions += ` <span title="${i18n.t("report.close")}" class="mdi mdi-archive datatable-pencil ml-1" onclick="confirmStatusChange(${dr.documentRequestId}, ${DOCUMENT_REQUEST_STATUS.COMPLETED}, ${DOCUMENT_REQUEST_STATUS.CLOSED})"></span>`;
          }
          else if (this.options.documentRequestStatus == DOCUMENT_REQUEST_STATUS.CLOSED && scope.userFunctions.find(uf => uf.id == FUNCTIONS.ACCESS_TO_ALL_DEALERS)){
            dr.actions += ` <span title="${i18n.t("report.reopen")}" class="mdi mdi-archive-minus datatable-pencil ml-1" onclick="confirmStatusChange(${dr.documentRequestId}, ${DOCUMENT_REQUEST_STATUS.CLOSED}, ${DOCUMENT_REQUEST_STATUS.COMPLETED})"></span>`;

          }
        }

        localScope.totalCount = response.totalCount;

        return response;
      },
      locale: getLanguageWithoutRegion(getLanguage()),
      langs: getTabulatorLangsObject(i18n, columnsLangData)
    });

    if (this.expandedByDefault) {
      this.expandDatatable = true;
    }
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.header-text {
  line-height: 20px;
}

.column-chip {
  width: 225px;
}
</style>