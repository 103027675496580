<template>
  <div :class="{ 'shrinkreport-content': drawer && !isMobile }" style="margin-top:65px;">
    <div>
      <Breadcrumbs />

      <v-text-field
        class="ml-3 mt-7"
        v-model="generalSearch"
        append-icon="mdi-magnify"
        :label="$t('general.search')"
        single-line
        hide-details
        dense
        filled
        clearable
        :loading="isSearching"
        @keydown="handleSearch"
        @click:clear="handleSearch"
        style="display:inline-block;min-width:400px;"
      ></v-text-field>
    </div>

    <v-container fluid v-if="columns">
      <DocumentRequestsDatatable 
            :datatableName="$t(DOCUMENT_REQUEST_STATUS_TEXT[DOCUMENT_REQUEST_STATUS.DRAFT])"
            :color=DOCUMENT_REQUEST_STATUS_COLOR[DOCUMENT_REQUEST_STATUS.DRAFT].text
            :documentRequestStatus=DOCUMENT_REQUEST_STATUS.DRAFT
            :expandedByDefault="true"
            :columns="columns"
            :generalSearch="search"
            @onShowMap="onShowMap"
       />
      <DocumentRequestsDatatable 
            :datatableName="$t(DOCUMENT_REQUEST_STATUS_TEXT[DOCUMENT_REQUEST_STATUS.SENT])"
            :color=DOCUMENT_REQUEST_STATUS_COLOR[DOCUMENT_REQUEST_STATUS.SENT].text
            :documentRequestStatus=DOCUMENT_REQUEST_STATUS.SENT
            :expandedByDefault="true"
            :columns="columns"
            :generalSearch="search"
            @onShowMap="onShowMap"
       />
      <DocumentRequestsDatatable 
            :datatableName="$t(DOCUMENT_REQUEST_STATUS_TEXT[DOCUMENT_REQUEST_STATUS.IN_PROGRESS])"
            :color=DOCUMENT_REQUEST_STATUS_COLOR[DOCUMENT_REQUEST_STATUS.IN_PROGRESS].text
            :documentRequestStatus=DOCUMENT_REQUEST_STATUS.IN_PROGRESS
            :expandedByDefault="true"
            :columns="columns"
            :generalSearch="search"
            @onShowMap="onShowMap"
       />
      <DocumentRequestsDatatable 
            :datatableName="$t(DOCUMENT_REQUEST_STATUS_TEXT[DOCUMENT_REQUEST_STATUS.COMPLETED])"
            :color=DOCUMENT_REQUEST_STATUS_COLOR[DOCUMENT_REQUEST_STATUS.COMPLETED].text
            :documentRequestStatus=DOCUMENT_REQUEST_STATUS.COMPLETED
            :expandedByDefault="true"
            :columns="columns"
            :generalSearch="search"
            @onShowMap="onShowMap"
       />
      <DocumentRequestsDatatable 
            :datatableName="$t(DOCUMENT_REQUEST_STATUS_TEXT[DOCUMENT_REQUEST_STATUS.CLOSED])"
            :color=DOCUMENT_REQUEST_STATUS_COLOR[DOCUMENT_REQUEST_STATUS.CLOSED].text
            :documentRequestStatus=DOCUMENT_REQUEST_STATUS.CLOSED
            :expandedByDefault="true"
            :columns="columns"
            :generalSearch="search"
            @onShowMap="onShowMap"
       />
      <DocumentRequestsDatatable 
            :datatableName="$t(DOCUMENT_REQUEST_STATUS_TEXT[DOCUMENT_REQUEST_STATUS.CANCELED])" 
            :color=DOCUMENT_REQUEST_STATUS_COLOR[DOCUMENT_REQUEST_STATUS.CANCELED].text
            :documentRequestStatus=DOCUMENT_REQUEST_STATUS.CANCELED
            :expandedByDefault="true"
            :columns="columns"
            :generalSearch="search"
            @onShowMap="onShowMap"
       />

    </v-container>
    <v-dialog v-model="showMap">
      <v-card>
        <v-card-title>
          {{ $t("reportInfo.site_address") }}
          <a v-if="marker" :href="'https://www.google.com/maps/dir/?api=1&destination=' + marker.position.toString().replace('(','').replace(')','')"  target="_blank" style="margin-left:15px;">{{ $t("report.get_directions") }}</a>
          <v-icon style="position:absolute;top:15px;right:15px;" @click="showMap = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <div id="map" style="height:75vh;width:100%;"></div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState, mapGetters } from "vuex";
import Breadcrumbs from "@/components/Breadcrumbs";
import DocumentRequestsDatatable from "@/components/DocumentRequestsDatatable"
import { FUNCTIONS, DOCUMENT_REQUEST_STATUS, DOCUMENT_REQUEST_STATUS_TEXT, DOCUMENT_REQUEST_STATUS_COLOR } from '@/enums'
import i18n from "@/i18n";

/*global google*/

export default {
  components: {
    Breadcrumbs,
    DocumentRequestsDatatable
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
      currentUser: state => state.currentUser,
      userFunctions: (state) => state.userFunctions,
      countries: (state) => state.countries,
    }),
    ...mapGetters({
      hasAccessToDocumentRequests: 'hasAccessToDocumentRequests'
    }),
  },
  data() {
    return {
      locale: this.$i18n.locale,
      FUNCTIONS: FUNCTIONS,
      DOCUMENT_REQUEST_STATUS: DOCUMENT_REQUEST_STATUS,
      DOCUMENT_REQUEST_STATUS_TEXT: DOCUMENT_REQUEST_STATUS_TEXT,
      DOCUMENT_REQUEST_STATUS_COLOR: DOCUMENT_REQUEST_STATUS_COLOR,
      showMap: false,
      map: null,
      marker: null,
      generalSearch: "",
      isSearching: false,
      search: "",
      columns: [
        {
          field: "formattedDealerName",
          title: i18n.t("filters.dealer"),
          widthGrow: 2,
          headerSort: true,
          active: true,
        },
        {
          field: "formattedUpdatedAt",
          title: i18n.t("report.updated_at"),
          widthGrow: 1,
          headerSort: true,
          active: true,
        },
        {
          field: "formattedProjectNumber",
          title: i18n.t("projects.project_number"),
          widthGrow: 1,
          headerSort: true,
          active: true,
        },
        {
          field: "formattedClient",
          title: i18n.t("reportInfo.client"),
          widthGrow: 2,
          headerSort: true,
          active: true,
          formatter: "html",
        },
        {
          field: "formattedSiteAddress",
          title: i18n.t("reportInfo.site_address"),
          widthGrow: 5,
          headerSort: true,
          active: false,
          formatter: "html",
        },
        {
          field: "formattedTypeEnum",
          title: i18n.t("documentRequests.typeEnum"),
          widthGrow: 2,
          headerSort: true,
          active: false,
        },
        {
          field: "actions",
          title: "",
          width: 70,
          height: 39,
          headerSort: false,
          active: true,
          hidden: true,
          formatter: "html",
          hozAlign: "center"
        },
      ]
    };
  },
  mounted() {
    if (this.hasAccessToDocumentRequests) {
      console.log('Document Requests mounted')

      this.$store.dispatch("setBreadcrumbs", [
        { text: this.$t("documentRequests.documentRequests"), href: "/documentRequests" },
      ]);

      for (let column of this.columns) {
        column.active = true;
      }
    }
    else {
      this.showErrorMessage(this.$t('general.access_denied'));
      this.$router.push('/');
    }
  },
  created: function() {
    console.log('Document Requests created')
  },
  beforeDestroy() {
    console.log('Document Requests unmounted')
  },
  methods: {
    ...mapMutations({
      openToast: 'openToast',
      showErrorMessage: 'showErrorMessage'
    }),
    ...mapActions({
      logout: 'logout'
    }),
    async onShowMap(report) {
      this.selectedReport = report;
      this.showMap = true;

      if (!this.map) {
        setTimeout(() => {
          this.initMap();
         }, 250);
      }
      else {
        this.updateMap();
      }
    },
    // Initialize and add the map
    initMap() { // eslint-disable-line

      // Fallback geolocation is TP headquarter
      let point = { lat: 46.11118448049068, lng: -71.2913423841532 };

      let report = this.selectedReport;
      if (report.siteAddressLatitude != null && report.siteAddressLongitude != null) {
        point = { lat: report.siteAddressLatitude, lng: report.siteAddressLongitude };
      }
      
      this.initMapWithPoint(point);
    },
    initMapWithPoint(point) {
      let mapElement = document.getElementById("map");
      console.log('mapElement', mapElement)
      this.map = new google.maps.Map(mapElement, {
        zoom: 10,
        center: point,
        gestureHandling: 'greedy'
      });

      this.marker = new google.maps.Marker({
        position: point,
        map: this.map,
      });
    },
    async updateMap() {
        let point = { lat: this.selectedReport.siteAddressLatitude, lng: this.selectedReport.siteAddressLongitude };

        if (this.marker != null) {
          this.marker.setMap(null);
        }
 
        this.marker = new google.maps.Marker({
            position: point,
            map: this.map
        });
        this.map.setCenter(point);
    },
    handleSearch() {
      let scope = this;
      this.isSearching = true;

      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(function () {
        scope.clearSearch();
      }, 1000);
    },
    clearSearch() {
      this.isSearching = false;
      this.search = this.generalSearch;
    },
  },
};

</script>

<style>
.badge-right-top {
  position: absolute!important;
  right: 20px;
  top: 20px;
}
.inlineblock {
  display: inline-block;
}
.v-badge--tile .v-badge__badge {
  border-radius: 3px;
}
td.text-start {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: normal !important;
}

#columns-menu {
  overflow:hidden; 
}

#filters-menu{
  overflow:hidden; 
}
#btn-add{
  background-color:#009f4d;
}
th, td {
  border-right: 1px solid #f1f1f1;
}
.v-breadcrumbs {
  display: inline-block;
}
</style>